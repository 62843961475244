.splash-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #021324;
  background-image: url('../images/logo_unifai.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30%;
  position: relative;
}

.version-text {
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: white;
  font-size: 85%;
  font-weight: bold;
}

@media (max-width: 768px) {
  .splash-container {
    background-size: 60%;
  }
}