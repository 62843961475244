/* Chat.css */

/* Root Variables */
:root {
  --primary-color: #007BFF;
  --secondary-color: #00C853;
  --accent-color: #FFAB00;
  --background-color: #F0F0F0;
  --text-color: #333333;
}

/* Chat Container */
.chat-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #0b253a; /* Dark blue background */
  color: #ffffff; /* Light text color for contrast */
}

/* Chat Header */
.chat-header {
  padding: 16px;
  background-color: #0e3b59; /* Slightly darker blue */
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index:2;
}

.chat-header h2,
.chat-header input {
  text-align: center;
  width: 100%;
  max-width: 400px; /* Set a max width to control the size */
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.chat-header input {
  padding: 1px 0 0;
  background-color: transparent;
  outline: none;
  border: none;
  color: #ffffff;
  margin: 0 auto;
  display: block;
}

.chat-nav {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.history-icon,
.new-chat-icon,
.settings-icon,
.context-icon {
  font-size: 24px;
  color: #ffffff;
  cursor: pointer;
  margin-left: 10px;
}

.header-metrics {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: #a8dadc;
  font-size: 77%;
}

/* Chat Messages */
.chat-messages {
  flex: 1;
  padding: 16px;
  overflow-y: auto;
  color: #ffffff;
}

/* Watermark */
.chat-messages::after {
  content: "";
  background-image: url('../images/logo_unifai.png'); /* Path to your logo */
  background-repeat: no-repeat;
  background-position: center;
  background-color: #021324;
  background-size: 25%; /* Adjust size as needed */
  opacity: 0.05; /* Subtle watermark effect */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  width: 100%;
  height: 100%;
  z-index: 0; /* Ensure it stays behind the content */
}


.message-group {
  margin-bottom: 1em;
  padding: 0.5em;
  border-bottom: 1px solid #ccc;
  position: relative;
  z-index: 1;
}

.message {
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 4px;
}

.user-message {
  background-color: #1f4d6e;
  align-self: flex-end;
}

.ai-message {
  background-color: #264b62; /* Darker background for assistant replies */
  color: #e0e1dd; /* Light text color for contrast */
  align-self: flex-start;
}

.message-content {
  margin: 0 0;
  word-wrap: break-word;
}

.message-meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  color: #c0c0c0;
  margin-top: 4px;
  padding-top: 4px;
  border-top: 1px solid #415a77;
  font-size: 0.85em;
  color: #a8dadc;
}

.message-meta .meta-info {
  display: flex;
  flex: 1;
}

.message-meta .meta-info span {
  margin-right: 16px; /* Add margin between items */
  text-align: left;   /* Align text to the left */
}

/* Chat Prompt */
.chat-prompt {
  display: flex;
  flex-direction: column;
  padding: 0 5px 20px;
  background-color: #0e3b59;
  border-top: 1px solid #ccc;
}

.input-container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: stretch; 
  padding: 5px 0;
  z-index: 1; 
}

.icons-container {
  display: flex;
  flex-direction: column;
  justify-content: center; 
  margin-right: 5px;
  padding-left: 5px; 
  padding-top:10px;
  z-index: 2; 
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.file-input-label,
.recording-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin-bottom: 8px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #ffffff;
  padding: 0;
  z-index: 3; 
}

.file-input-label:last-child,
.recording-button:last-child {
  margin-bottom: 0;
}

.recording-button{
  border-radius:5px;
  cursor:pointer;
}

.recording-button.stop {
  background-color: red;
}

.recording-button.transcribing {
  background-color: gray;
  opacity: 0.5;
  cursor: not-allowed;
}


.input-wrapper textarea {
  width: auto;
  padding: 8px;
  margin-bottom:0;
  font-size: 16px;
  border: none;
  resize: none;
  outline: none;
  background-color: #1f4d6e;
  color: #ffffff;
  max-width:98%;
  min-height: 56px; /* Match the height of the two stacked icons */
}

.input-container.drag-over {
  border: 2px dashed var(--primary-color);
}

.drag-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  pointer-events: none;
}

.file-previews {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Adjust the gap as needed */
  margin-bottom: 10px;
}

.file-preview {
  position: relative;
  display: inline-block; /* Align images horizontally */
  margin-right: 10px; /* Space between images */
  margin-bottom: 10px;
}

.file-preview img {
  max-width: 100px;
  max-height: 100px;
  border-radius: 10px;
}

.file-preview .remove-btn {
  position: absolute;
  top: -3px;
  right: 5px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 12px;
  line-height: 12px;
}

.chat-controls {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.chat-controls select {
  padding: 8px;
  margin: 0 10px 0 35px;
  font-size: 14px;
  border: none;
  border-radius: 3px;
  background-color: #ffffff;
  outline: none;
}

.chat-controls button {
  padding: 8px 16px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.message-container {
  display: flex;
  flex-direction: column;
  gap: 8px; /* Adjust the gap between the image and text as needed */
}

.loading_animation{
  padding:10px auto ; 
}

.image-thumbnails {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.image-thumbnails img {
  max-width: 100px;
  max-height: 100px;
  border-radius: 8px;
  cursor: pointer;
}

.message-container img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  cursor: pointer;
  display: block;
  margin: 30px auto;
}

/* Styles for markdown content */
.message-content p {
  margin: 0;
  padding: 0;
}

.message-content strong {
  font-weight: bold;
}

.message-content em {
  font-style: italic;
}

/* Styles for pre and code elements */
.message-content pre,
.message-content code {
  background-color: #1e1e1e; /* Dark background */
  color: #c5c5c5; /* Bright text */
  padding: 10px;
  border-radius: 5px;
  overflow-x: auto;
}

.message-content code {
  padding: 2px 4px;
}

pre {
  white-space: pre-wrap;       /* CSS 3 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

/* Styles for headers */
.message-content h1,
.message-content h2,
.message-content h3,
.message-content h4,
.message-content h5,
.message-content h6 {
  color: #ffffff;
}

.message-content h1 {
  font-size: 1.5em;
  margin: 0.67em 0;
}

.message-content h2 {
  font-size: 1.25em;
  margin: 0.75em 0;
}

.message-content h3 {
  font-size: 1.1em;
  margin: 0.83em 0;
}

.message-content h4 {
  font-size: 1em;
  margin: 1.12em 0;
}

.message-content h5 {
  font-size: 0.875em;
  margin: 1.5em 0;
}

.message-content h6 {
  font-size: 0.85em;
  margin: 1.67em 0;
}

.code-container {
  position: relative;
}

.code-container pre {
  margin: 5px; 
}

.copy-button {
  position: absolute;
  top: 10px;
  right: 15px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.copy-button:hover {
  background: #0056b3;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin: 0px 32px; /* Adjust margin as needed */
}

.checkbox-container input {
  margin-right: 10px; /* Space between checkbox and label */
}

.total-cost,
.total-tokens {
  font-size: 16px;
}


/* Responsive Styles */
@media (max-width: 768px) {
  .chat-header {
    flex-direction: column;
    align-items: flex-start;
    padding: 5px 5px;
  }

  .chat-nav {
    top: 48px;
  }

  .chat-header h2,
  .chat-header input {
    font-size: 16px;
    max-width: 100%;
    margin-bottom: 8px;
    text-align: center;
  }

  .chat-prompt{
    padding: 0px 12px 5px;
  }

  .header-metrics {
    width: 100%;
    position: initial;
    transform: initial;
  }

  .total-cost,
  .total-tokens {
    width: 100%;
    text-align: right;
    font-size:100%;
  }

  .message-meta .meta-info {
    flex-direction: column;
    align-items: flex-start;
  }

  .chat-controls {
    flex-direction: column;
  }

  .chat-controls select,
  .chat-controls button {
    width: 100%;
    margin: 4px 0;
  }

  .settings-icon,
  .history-icon,
  .new-chat-icon,
  .context-icon {
    font-size: 20px;
  }

  .message-container img {
    max-width: 100%; 
    margin: 10px auto;
  }

  .chat-messages::after {
    background-size: 55%; /* Adjust size as needed */
  }
}