.settings-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #0b253a; /* Dark blue background */
  color: #ffffff; /* Light text color for contrast */
}

.settings-header {
  padding: 16px;
  background-color: #0e3b59; /* Slightly darker blue */
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.settings-header h2 {
  text-align: center;
  width: 100%;
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.settings-content {
  flex: 1;
  padding: 16px;
  overflow-y: auto;
  color: #ffffff;
}

.settings-button {
  padding: 8px 16px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 16px;
}

.settings-info {
  margin-top: 32px;
}

.settings-info h3 {
  margin-bottom: 8px;
  font-size: 1.5em;
}

.settings-info p {
  font-size: 1em;
  line-height: 1.5;
}

.auth-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1f4d6e;
  padding: 32px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  width: 70%;
  max-width: 400px; /* Ensure responsiveness */
  text-align: center;
  z-index: 1000;
}

.auth-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.auth-modal h3 {
  margin-bottom: 16px;
  font-size: 1.5em;
  color: #ffffff;
}

.auth-modal p {
  font-size: 1em;
  margin-bottom: 16px;
  color: #ffffff;
}

.auth-modal input {
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #ffffff;
  color: #000000;
}

.auth-modal button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 8px 0;
}

.auth-modal .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #ffffff;
  font-size: 20px;
  cursor: pointer;
}

.auth-modal .or-divider {
  margin: 16px 0;
  color: #ffffff;
}

.user-info{
  margin-bottom:20px;
  padding-bottom:20px;
  border-bottom:1px solid #ccc;
}

.user-info p {
  font-size: 1em;
  margin-bottom: 8px;
}

.sign-in-button {
  background-color: #007bff;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  display: block;
}

@media (max-width: 768px) {
  .settings-header h2 {
    font-size: 16px;
    text-align: right;
  }

  .settings-header .chat-nav{
    top: 25px;
  }
}

/* styles/Settings.css */
.lottie-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  background:#1C293C
}

.lottie-container > div {
  border:1px solid red;
}
