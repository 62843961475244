/* History.css */

/* Root Variables */
:root {
  --primary-color: #007BFF;
  --secondary-color: #00C853;
  --accent-color: #FFAB00;
  --background-color: #F0F0F0;
  --text-color: #333333;
}

/* History Container */
.history-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #0b253a; /* Dark blue background */
  color: #ffffff; /* Light text color for contrast */
}

.history-header {
  padding: 16px;
  background-color: #0e3b59; /* Slightly darker blue */
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.history-header h2 {
  text-align: center;
  width: 100%;
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.history-icon {
  font-size: 24px;
  color: #ffffff;
  cursor: pointer;
  margin-left: 10px;
}

.history-metrics {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: #a8dadc;
  font-size: 77%;
  text-align: right;
}

.history-messages {
  flex: 1;
  padding: 16px;
  overflow-y: auto;
  color: #ffffff;
}

.history-date-group {
  margin-bottom: 45px;
}

.history-date-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1em;
  margin: 10px 0 0 0;
  padding: 0 8px;
}

.history-date-header h3 {
  margin: 0 0 0;
}

.day_metrics {
  display: flex;
  align-items: center;
}

.day_metrics span {
  margin-left: 5px;
}

.day_metrics span:after {
  content: ", ";
}

.day_metrics span:last-child:after {
  content: "";
}

.history-message-group {
  margin-bottom: 0;
  margin-top: 5px;
  border-bottom: none;
  padding: 2px 8px;
}

.history-message {
  margin-bottom: 0;
  padding: 0;
  border-radius: 0;
}

.history-message-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 0;
  word-wrap: break-word;
}

.history-message-content strong {
  flex: 1;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.history-session-meta {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
}

.history-session-meta .meta-info {
  display: flex;
  flex-direction: column;
  text-align: right;
  margin-right: 10px;
}

.history-session-meta .delete-icon {
  cursor: pointer;
  color: #ff6b6b; /* Red color for delete icon */
}

.history-date-header span,
.history-session-meta .meta-info span {
  color: #a8dadc;
  font-size: 77%;
}

.history-hr {
  border: none;
  border-top: 1px solid #ccc;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .history-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .history-header h2 {
    font-size: 16px;
    max-width: 100%;
    margin-bottom: 8px;
    text-align: right;
  }

  .history-metrics {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: initial;
    transform: initial;
  }

  .history-header .chat-nav{
    top: 25px;
  }

  .history-date-header {
    align-items: flex-start;
  }

  .history-date-header h3 {
    font-size: 1em;
    margin-bottom: 8px;
  }

  .day_metrics {
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
    margin-left: 0;
  }

  .day_metrics span:after {
    content: "";
  }

  .history-message-content strong {
    flex-direction: column;
    align-items: flex-start;
    font-size: 85%;
  }

  .history-session-meta {
    display: flex;
    align-items: flex-end;
  }

  .history-session-meta .meta-info {
    flex-direction: column;
  }

  .history-session-meta .delete-icon {
    margin-top: 8px;
  }
}
