/* SystemContextModal.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1f4d6e; /* Matches your other modal background color */
  padding: 32px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  width: 70%;
  max-width: 400px; /* Ensure responsiveness */
  text-align: center;
  z-index: 1000;
  color: #ffffff; /* Ensure text is white for contrast */
}

.modal-content h2 {
  margin: 0;
  font-size: 1.5em;
  color: #ffffff;
}

.modal_leadtext {
  text-align: left;
}

.input-group {
  margin-bottom: 16px;
  text-align: left;
}

.input-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.input-group input,
.input-group select,
textarea {
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #ffffff;
  color: #000000;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
}

.modal-content button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 5px;
}

.modal-content button:hover {
  background-color: #0056b3;
}

.modal-content .contextSelect{
  border-bottom:1px solid #ccc;
}
.modal-content #contextSelect{
  width:100%;
}
.modal-content .contextText{
  min-height:80px;
  height:100vh;
  max-height:140px;
  margin-bottom:0;
}

.checkbox-group input{
  width:initial;
  display:inline-block;
  margin:0;
  vertical-align:middle;
}

.checkbox-group label {
  display:inline-block;
  font-size:85%;
  margin-left: 8px;
  font-weight: normal;
  color: #ffffff;
}